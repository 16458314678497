import React, { ReactElement } from 'react'
import { navigate } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import { Section, SectionProps } from './Section'
import { Container } from '../Layout'
import '../../styles/kontakt.css'
import { removeSlashes } from '../../lib'

type SectionKontaktProps = SectionProps & {
  data?: Array<{
    icon?: ReactElement
    title?: string
    subtitle?: string
    actionLinks?: Array<{
      label: string
      url?: string
      variant?: string
    }> | null
  }> | null
}

const defaultProps: Partial<SectionKontaktProps> = {
  data: null,
}

const SectionKontakt = (props: SectionKontaktProps): ReactElement => {
  const { data, containerClass } = props
  const { locale } = useLocalization()

  return (
    <Section containerClass={containerClass}>
      <Container>
        {data && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-5 ">
            {data.map(({ icon, title, subtitle, actionLinks }) => (
              <div className="contactbox">
                <div className="box-content">
                  <h3
                    className="text-center text-2xl my-4"
                    style={{ color: '#33444C', fontFamily: 'Roboto Slab' }}
                  >
                    {title}
                  </h3>
                  <p
                    className="text-center"
                    style={{ color: '#8C8C8C', marginBottom: '13px' }}
                  >
                    {subtitle}
                  </p>
                </div>
                <div className="box-inner">
                  <div className="hex-kontakt hex-outer">
                    <div className="hex-kontakt hex-inner">
                      <img src={icon} alt="icon" />
                    </div>
                  </div>
                </div>
                {actionLinks &&
                  actionLinks.map(({ label, url, variant }) => (
                    <div
                      className="bg-black text-white p-3 absolute text-center left-0 right-0 -bottom-5 ml-auto mr-auto"
                      style={{
                        backgroundColor: `${!url && '#ccc'}`,
                        cursor: `${url && 'pointer'}`,
                        width: 'fit-content',
                        padding: '8px 20px',
                        borderRadius: '0.25em',
                      }}
                    >
                      {variant === 'link' ? (
                        <a href={url} target="_top">
                          {label}
                        </a>
                      ) : (
                        <button
                          type="button"
                          className={`${!url && 'cursor-default'}`}
                          onClick={() =>
                            url && navigate(`/${locale}/${removeSlashes(url)}`)
                          }
                        >
                          {label}
                        </button>
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        )}
      </Container>
    </Section>
  )
}

SectionKontakt.defaultProps = defaultProps

export { SectionKontakt }
