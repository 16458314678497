import { StaticImage } from 'gatsby-plugin-image'
import React, { ReactElement, useEffect } from 'react'
import Theme, { Layout, SectionHero, SEO } from '../components'
import { SectionKontakt } from '../components/Section/SectionKontakt'
import page from '../data/pages/kontakt.yml'
import support from '../images/contact-icons/support.svg'
import vertrieb from '../images/contact-icons/vertrieb.svg'
import sonstige from '../images/contact-icons/sonstige.svg'
import { SectionNewsletter } from '../components/Section/SectionNewsletter'
import '../styles/contactFormular.css'
import { SectionKontaktFormular } from '../components/Section/SectionKontaktFormular'

declare global {
  interface Window {
    hbspt: any
  }
}

export default function KontaktPage(): ReactElement {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '7744677',
          formId: 'cdfe21ee-57f5-41c5-bf4c-acddf965579d',
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <>
      <SEO title={page?.title} />
      <div
        className="fixed z-0 w-full h-full top-0 left-0 "
        style={{
          mixBlendMode: 'screen',
          opacity: '0.8',
          backdropFilter: 'blur(3px)',
        }}
      >
        <StaticImage
          src="../images/jellyfish.jpg"
          alt="Quallen"
          objectFit="cover"
          className="w-full h-full select-none"
          draggable={false}
        />
      </div>
      <Theme>
        <Layout>
          <SectionHero
            title="Kontakt mit payactive"
            titleTag="h1"
            subtitle="Hast du spezielle Fragen oder magst du uns Feedback zukommen lassen? Gleich wie, wir freuen uns auf deine Nachricht."
            containerClass="pb-0 "
          />

          <SectionKontakt
            containerClass="pt-0 pb-14"
            data={[
              {
                icon: support,
                title: 'Technischer Support',
                subtitle:
                  'Bei Problemen mit einem Zahlungsvorgang oder mit der payactive Plattform schreibe bitte eine E-Mail an:',
                actionLinks: [
                  {
                    label: 'support@payactive.eu',
                    url: 'mailto:support@payactive.eu?subject=Request for information',
                    variant: 'link',
                  },
                ],
              },
              {
                icon: vertrieb,
                title: 'Vertrieb',
                subtitle:
                  'Wenn du gerne eine Produktdemo der payactive Plattform oder eine sonstige Anfrage an unser Vertriebsteam hast:',
                actionLinks: [
                  {
                    label: 'Termin vereinbaren',
                    url: 'termin-investoren',
                  },
                ],
              },
              {
                icon: sonstige,
                title: 'Presse-Anfragen',
                subtitle:
                  'Für Fragen von Journalist:innen stellen wir demnächst eine eigene Seite bereit. Nutze solange bitte einfach das Formular unten.',
                actionLinks: [
                  {
                    label: 'Bald verfügbar',
                  },
                ],
              },
            ]}
          />
          <SectionKontaktFormular
            title="Kontaktformular"
            bulletsTitle="Wie können wir helfen?"
            formular={<div id="hubspotForm" />}
            bullets={[
              {
                title: 'Allgemeines zum Unternehmen?',
              },
              {
                title: 'Payment Plattform kennenlernen?',
              },
              {
                title: 'Mehr zum Social Cashback?',
              },
            ]}
          />

          <SectionNewsletter containerClass="" page="Startseite" />
        </Layout>
      </Theme>
    </>
  )
}
